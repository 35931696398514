import React from "react";
import AnimatedInView from "../../components/HOC/AnimatedInView";
import "./FAQList.scss";
import {generate as counter} from "shortid";
import variants from "../../utils/variants";
import useWinSize from "../../utils/useWinSize";

const getAnimation = (index, screenWidth) => {
  if(screenWidth > 960) {
    return index % 2 ? variants.fadeRight : variants.fadeLeft
  } else {
    return variants.fade
  }
}

const FAQItem = ({ title, text, index }) => {
  const {width:screenWidth} = useWinSize();
  
  return (
    <AnimatedInView threshold={0.2} duration={1} variants={getAnimation(index, screenWidth)} className="faq-list__item">
      <h3 className="faq-list__title">{title}</h3>
      <p className="faq-list__text">{text}</p>
    </AnimatedInView>
  );
}

const FAQList = ({ items }) => {
  const faqItems = items.map((f, k) => <FAQItem key={counter()} index={k} {...f} />);
  return <div className="faq-list">{faqItems}</div>;
};

export default FAQList;
