import React from "react";
import styled from "styled-components";

const Line = (props) => {
  return <OneLine {...props} />;
};

const OneLine = styled.div`
  position: absolute;
  ${({ size, position }) => ({ ...size, ...position })};
  background-color: ${({color}) => color || "#2f2d4c"};
`;

export default Line;
