import * as React from "react";
import Line from "../Line";
import './Modal.scss';

export const handleModalClose = (target, setModal) => {
  if (target.classList.contains('modal') || target.classList.contains('modal__cross')) {
    setModal(null);
  }
}

const Modal = ({onClick, children}) => {
  return <div className="modal" onClick={onClick}>
    <div className="modal__inner">
      <div className="modal__cross" onClick={onClick}>
        <Line size={{width: "100%", height: "1px"}} position={{transform: "translateY(15px) rotate(45deg)"}}
              color="#fff"/>
        <Line size={{width: "100%", height: "1px"}} position={{transform: "translateY(15px) rotate(-45deg)"}}
              color="#fff"/>
      </div>
      {children}
    </div>
  </div>
};

export default Modal

