import React from 'react';
import Button from '../Button';
import * as yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from "formik";
import axios from "axios";

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Имя должно содержить больше 2 символов")
    .required("Это поле обязательно для заполнения"),
  email: yup
    .string()
    .email("Введите действующий email")
    .required("Это поле обязательно для заполнения"),
  message: yup
    .string()
    .min(5, "Сообщение должно содержать больше 5 символов")
    .required("Это поле обязательно для заполнения")
})

const FeedBackForm = ({handleClose}) => {
  const handleSubmit = async ({name, email,message}, {setSubmitting, resetForm}) => {
    const URL = 'https://script.google.com/macros/s/AKfycbxuWyibwrFfrcRD_3YrFxJpRpp_zeKZRn7_q2j5Y5gyeRaePWzmWoSiIkeFvIDpo4w/exec';
    await axios.get(`${URL}?name=${name}&email=${email}&message=${message}`);
    setSubmitting(false);
    resetForm();
    handleClose();
  }
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: ""
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({touched, isValid, isSubmitting}) => (
        <Form className="modal__form">
          <Field name="name" placeholder="Имя" className="modal__field"/>
          {touched.name && (
            <span className="modal__field--error"><ErrorMessage name="name"/></span>
          )}
          <Field name="email" placeholder="Email" className="modal__field"/>
          {touched.email && (
            <span className="modal__field--error"><ErrorMessage name="email"/></span>
          )}
          <Field name="message" component="textarea" placeholder="Сообщение"
                 className="modal__field modal__field--multiline"/>
          {touched.message && (
            <span className="modal__field--error"><ErrorMessage name="message"/></span>
          )}
          <Button disabled={!isValid || isSubmitting} type="contained" color="#0048d6" text="Отправить"
                  className="modal__btn"/>
        </Form>
      )}
    </Formik>
  );
};

export default FeedBackForm;