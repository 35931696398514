import React from 'react';
import Button from '../Button';
import * as yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from "formik";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Введите действующий email")
    .required("Это поле обязательно для заполнения"),
  password: yup
    .string()
    .min(5, "Пароль должно содержать больше 5 символов")
    .required("Это поле обязательно для заполнения")
})

const LoginForm = ({handleClose}) => {
  const handleSubmit = (formData, {setSubmitting, resetForm}) => {
    setSubmitting(false);
    resetForm();
    handleClose();
  }
  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({touched, isValid, isSubmitting}) => (
        <Form className="modal__form">
          <Field name="email" placeholder="Email" className="modal__field"/>
          {touched.email && (
            <span className="modal__field--error"><ErrorMessage name="email"/></span>
          )}
          <Field name="password" placeholder="Пароль"
                 className="modal__field"/>
          {touched.password && (
            <span className="modal__field--error"><ErrorMessage name="password"/></span>
          )}
          <Button disabled={!isValid || isSubmitting} type="contained" color="#0048d6" text="Войти"
                  className="modal__btn"/>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;