import React, {useState} from "react";
import {motion} from "framer-motion";
import Line from "../Line.jsx";
import Icons from "../../utils/Icons/index";
import "./PlanetTabs.scss";
import Baloon from "../Baloon";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper/core';
import "swiper/swiper.scss";
import Arrow from '../../images/arrow-prev.svg'
import useWinSize from "../../utils/useWinSize";
import {generate as counter} from "shortid";

SwiperCore.use([Navigation]);

const PlanetTabsMobile = ({data}) => {
  const [slider, setSlider] = useState(null);
  const {width:screenWidth} = useWinSize();
  const sliderProps = {
    loop: true,
    effect: 'fade',
    navigation: true,
    autoHeight: true,
    onInit: (swiper) => setSlider(swiper),
  };
  return (
    <section className="planet-tabs ">
      <div className="container">
        <Baloon
          size="large"
          opacity={0.24}
          position={{top: "-400px", right: "-15%"}}
        />
        <div className="planet-menu">
          <div
            id="fuck"
            className="planet-bg-wrapper planet-bg-wrapper--mobile"
          >
            <Line
              size={{width: "1px"}}
              position={{
                top: "-15%",
                left: "50%",
                bottom: screenWidth >= 480 ? "100%" : "98%",
                transform: "translateX(-50%)",
              }}
            />
            <div className="company planet-bg"/>
            <motion.div
              whileHover={{height: 40}}
              whileTap={{height: 30}}
              className="planet-company">
              <Icons.Company/>
            </motion.div>
          </div>
        </div>
        <Swiper {...sliderProps} className="planet-tabs__slider">
          <div className="planet-tabs__btn planet-tabs__btn--prev" onClick={() => slider.slidePrev(300)}>
            <img src={Arrow} alt="arrow prev"/>
          </div>
          <div className="planet-tabs__btn planet-tabs__btn--next" onClick={() => slider.slideNext(300)}>
            <img src={Arrow} alt="arrow next"/>
          </div>
          {data.map((tab, k) => <SwiperSlide key={counter()}>
            <div
              id="tabsContent"
              className={`planet-content__item`}
            >
              <div className="planet-content__title">
                <img src={tab.img} alt={tab.title}/>
                <h3>{tab.title}</h3>
              </div>
              <p className="planet-content__text">{tab.text}</p>
            </div>
          </SwiperSlide>)}
        </Swiper>
      </div>
      <Baloon
        size="medium"
        opacity={0.24}
        position={{bottom: "-10%", left: "-10%"}}
      />
    </section>
  );
};

export default PlanetTabsMobile;
