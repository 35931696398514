import React from "react";
import styled from "styled-components";

const Text = ({ size = "18px", weight = 400, children, className, noMedia=false }) => {
  return <p className={className}>{children}</p>;
};

const TextP = styled(Text)`
  font-family: "Graphik", sans-serif;
  font-size: ${({ size }) => `${size || 18}px`};
  font-weight: ${({ weight }) => weight || 400};
  color: #fff;
  
  ${({noMedia}) => !noMedia && `
    @media (max-width: 1100px) {
      font-size: ${({size}) => `${(parseInt(size) || 18) * 0.8}px`};
    }
    @media (max-width: 768px) {
      font-size: ${({size}) => `${(parseInt(size) || 18) * 0.7}px`};
    }
    @media (max-width: 480px){
      font-size: ${({size}) => `${(parseInt(size) || 18) * 0.6}px`};
    }
    @media (max-width: 375px){
      font-size: ${({size}) => `${(parseInt(size) || 18) * 0.5}px`};
    }
  `}
`;

export default TextP;
