import React from "react";
import "scss-reset/src/scss/_reset.scss";
import "../page-styles/index.scss";
import Header from "./Header/Header.jsx";
import Footer from "./Footer/Footer.jsx";
import Link from "../utils/Link";

const Layout = ({ children, title, isHome }) => {
  const headerLinks = [
    new Link("О нас", "/#about"),
    new Link("Пассивный доход", "/#passiveIncome"),
    new Link("Экосистема", "/#ecoSystem"),
    new Link("FAQ", "/#faq"),
  ];
  const footerLinks = [
    [
      new Link("О нас", "/#about"),
      new Link("Пассивный доход", "/#passiveIncome"),
    ],
    [new Link("Экосистема", "/#ecoSystem"), new Link("LP Token", "/#token")],
    [new Link("F.A.Q.", "/#faq"), new Link("Свазяться", "/#connect")],
    [new Link("Смарт-контракт EVERIN PRO и EVERIN ULTRA", "https://tronscan.io/#/contract/TCHVdYCij8bNLBpKqHKdN9ZbpM1R2as2Dx/code"),
      new Link("Смарт-контракт токена EVER", "https://tronscan.org/#/contract/TEsfLEHgNRR37SmeACtJ8DRvDWFtUvoiNM/code"),
      new Link("Смарт-контракт стейк пула", "https://tronscan.io/#/contract/TAcCuz44TpasuDsQPpxpUEAKRRPnCLKdW7/code")]
  ];

  return (
    <>
      <title>{title}</title>
      <Header isHome={isHome} links={headerLinks} />
      {children}
      <Footer links={footerLinks} />
    </>
  );
};

export default Layout;
