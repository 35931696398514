import React, {useState} from "react";
import "./AboutCardList.scss";
import variants from "../../utils/variants";
import AnimatedInView from "../../components/HOC/AnimatedInView";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import useWinSize from "../../utils/useWinSize";
import {generate as counter} from "shortid";
import Modal, {handleModalClose} from "../Modal/Modal";
import Text from "../typography/Text/Text";
import ReactTooltip from "react-tooltip";

export const AboutCard = ({numbers, img, tooltip, openModal, name}) => {
  const n = counter();
  const {width: screenWidth} = useWinSize();
  const modal = <Modal onClick={({target}) => handleModalClose(target, openModal)}>
    <Text size={15}>{tooltip}</Text>
  </Modal>
  
  return (
    <div className="about-card">
      <p className="about-card__numbers">{numbers}</p>
      
      {screenWidth > 960
        ? <span data-tip data-for={`aboutCardTooltip${n}`} className="about-card__tooltip">i</span>
        : <span className="about-card__tooltip" onClick={() => openModal(modal)}>i</span>}
      
      
      {img && <img src={img} alt="card img" height="34px"/>}
      <p className="about-card__name">{name}</p>
      {screenWidth > 960 && <ReactTooltip
        id={`aboutCardTooltip${n}`}
        aria-haspopup="true"
        role="example"
        className={'about-card__tooltip-content'}
      >{tooltip}</ReactTooltip>}
    </div>
  );
};

const AboutCardList = ({items}) => {
  const {width: screenWidth} = useWinSize();
  const [modal, setModal] = useState(null);
  const itemsCards = items.map((c, k) => <AboutCard openModal={setModal} {...c} key={counter()}/>);
  
  if (screenWidth <= 768) {
    return <>
      {modal}
      <AnimatedInView
        variants={variants.fadeRight}
        threshold={0.2}
        duration={1}>
        <Swiper
          slidesPerView={screenWidth > 480 ? 2 : 1}
          centeredSlides={false}
          slidesOffsetBefore={20}
          spaceBetween={-50}
          className="cards-list"
        >
          {itemsCards.map((c, k) => <SwiperSlide key={counter()}>{c}</SwiperSlide>)}
        </Swiper>
      </AnimatedInView>
    </>
  } else {
    return <>
      {modal}
      <AnimatedInView
        variants={variants.fadeLeft}
        threshold={0.2}
        duration={1} className="container cards-list">{itemsCards}</AnimatedInView>
    </>;
  }
};

export default AboutCardList;