import React, {useEffect, useState} from "react";
import "./Header.scss";
import {motion} from "framer-motion";
import Icons from "../../utils/Icons";
import Button from "../Button";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import useWinSize from "../../utils/useWinSize";
import {generate as counter} from "shortid";
import LoginForm from "../Forms/LoginForm";
import Modal, {handleModalClose} from "../Modal/Modal.jsx";
import Title from "../typography/Title/Title";

const Header = ({links, isHome}) => {
  const {width: screenWidth} = useWinSize();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const toggleOpen = () => setOpen(!open);
  const handleLogin = () => {
    // setModal(<Modal onClick={({target}) => handleModalClose(target, setModal)}>
    //   <Title className="modal__title">Вход</Title>
    //   <LoginForm handleClose={({target}) => handleModalClose(target, setModal)}/>
    // </Modal>)
  }
  
  const linkElems = links.map((l, k) => (
    <li
      key={counter()}
      className="header-menu__item"
    >
      <AnchorLink to={l.url} title={l.title}>
        <span
          className="header-menu__link"
        >
          {l.title}
        </span>
      </AnchorLink>
    </li>
  ));
  
  return (
    <>
      {modal}
      <header className={`${isHome ? "header" : ""} container--first-screen`}>
      <div className="header-nav">
        <Icons.Company className="header-company"/>
        
        {screenWidth > 960 ?
          <div>
            <menu className="header-menu">
              {linkElems}
            </menu>
            <Button text="Вход" type="outlined" onClick={handleLogin} className="header-login"/>
          </div>
          : <menu className="header-menu header-menu--mobile" onClick={(e) => toggleOpen(e)}>
            <div id="sidebar" className={open ? "open" : ''}>
              <div className="header-company"><Icons.Company /></div>
              
              <div className="header-menu__items">{linkElems}</div>
              
              <Button text="Вход" type="outlined" onClick={handleLogin} className="header-login"/>
            </div>
            <div className="header__line"/>
            <div className="header__line"/>
            <div className="header__line"/>
          </menu>
        }
      </div>
      
      {isHome && (
        <div>
          <h1 className="header__title">
            Your own digital decentralized financial ecosystem.
          </h1>
          <p className="header__subtitle">
            Управляется только участниками. Существует только для участников.
          </p>
          <div>
            <Button
              text="Регистрация"
              linkURL="#"
              type="contained"
              color="#0048D6"
              className="header__btn"
            />
            <Button
              text="White paper"
              linkURL="#"
              type="contained"
              color="#fff"
              textColor="#000"
              className="header__btn header__btn--white"
            />
          </div>
        </div>
      )}
    </header></>
  );
};

export default Header;
