import React from "react";

const Twitter = (props) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Artboard</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-627.000000, -633.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Group" transform="translate(627.000000, 633.000000)">
            <path
              d="M18.2792616,46.4687465 C23.9094561,46.4687465 28.8970753,45.0305984 33.2421166,42.1543037 C37.2199712,39.5839973 40.3104591,36.1263228 42.5135789,31.7812815 C44.6555004,27.6810304 45.7264618,23.4277858 45.7264618,19.0215463 C45.7264618,18.4707664 45.6958634,18.0729813 45.6346652,17.8281898 C47.5317965,16.4206415 49.1535365,14.7377019 50.4998879,12.7793737 C48.6639548,13.5749452 46.8280217,14.0951253 44.9920886,14.3399168 C47.0728132,13.0547637 48.4803615,11.2800288 49.2147348,9.01571079 C47.3176034,10.1172707 45.2674785,10.8822423 43.0643588,11.3106272 C42.1463922,10.3926606 41.0907307,9.65828739 39.8973742,9.10750745 C38.7040176,8.55672751 37.4035645,8.28133754 35.9960163,8.28133754 C34.2824782,8.28133754 32.6913367,8.70972241 31.2225902,9.56649073 C29.7538437,10.4232591 28.5910855,11.6013171 27.7343172,13.100662 C26.8775489,14.6000069 26.449164,16.2064484 26.449164,17.9199864 C26.449164,18.6543597 26.5103623,19.3887329 26.6327573,20.1231062 C22.7160996,19.9395129 19.0289348,18.9603481 15.5712603,17.1856132 C12.1135858,15.4108783 9.19139268,13.0241652 6.80467962,10.025474 C5.88671306,11.5554187 5.42772978,13.1618602 5.42772978,14.8447985 C5.42772978,16.5277367 5.82551482,18.0729813 6.62108631,19.4805296 C7.4166578,20.8880779 8.45701943,22.050836 9.74217262,22.9688025 C8.21222788,22.9076043 6.7740812,22.4792208 5.42772978,21.6836493 L5.42772978,21.8672427 C5.42772978,24.1315606 6.16210303,26.1510871 7.63084953,27.925822 C9.09959602,29.7005568 10.9355291,30.8327165 13.1386489,31.3222982 C12.2818806,31.5058915 11.4251108,31.5976882 10.5683425,31.5976882 C10.0175626,31.5976882 9.43618423,31.5670898 8.82420606,31.5058915 C9.43618423,33.4030229 10.5530426,34.9788646 12.174784,36.2334193 C13.7965254,37.4879741 15.6783568,38.1152508 17.8202784,38.1152508 C14.2708072,40.8691505 10.2623541,42.2461003 5.7949164,42.2461003 C4.93814808,42.2461003 4.17317501,42.2155019 3.5,42.1543037 C7.96743773,45.0305984 12.8938573,46.4687465 18.2792616,46.4687465 Z"
              id=""/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Twitter;
