import React from "react";
import "./Footer.scss";
import Icons from "../../utils/Icons";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import Text from "../typography/Text/Text";
import useWinSize from "../../utils/useWinSize";
import {generate as counter} from "shortid";


const Footer = ({links}) => {
  const {width: screenWidth} = useWinSize();
  const linksElems = links.map((col, k) => (
    <div key={counter()} className="footer__col">
      {col.map((link) => (
        link.url.indexOf('http') >= 0
          ? <a key={counter()} href={link.url} target="_blank" className="footer__link">{link.title}</a>
          : <AnchorLink
            key={counter()}
            to={link.url}
            title={link.title}
            className="footer__link"
          >
            {link.title}
          </AnchorLink>
      ))}
    </div>
  ));
  
  return (
    <footer>
      <div className="container--first-screen footer">
        
        <div className="footer__company">
          <Icons.Company/>
        </div>
        {
          screenWidth > 1100
            ? linksElems
            : <div className="footer__links">{linksElems}</div>
        }
        
        <div className="footer__social">
          <h3 className="footer__link footer__link--no-hover">Social</h3>
          <a href="https://twitter.com/EverInENG" target="_blank" className="footer__social-icon">
            <Icons.Twitter/>
          </a>
          <a href="https://www.facebook.com/Everinglobal" target="_blank" className="footer__social-icon">
            <Icons.FaceBook/>
          </a>
          <a href="https://t.me/everinglobal" target="_blank" className="footer__social-icon">
            <Icons.Telegram/>
          </a>
          <a href="https://www.youtube.com/channel/UCMDXYIG2RpfEoENUOnaUPeA/featured" target="_blank" className="footer__social-icon">
            <Icons.Youtube/>
          </a>
        </div>
      </div>
      
      <Text size={13} className="container--first-screen footer__text">
        Сайт носит исключительно информационный характер об инструменте Everin для участников сообщества Everin. Сайт не
        собирает и не хранит активы пользователей Everin. Everin – это p2p инструмент, с помощью которого пользователи
        неконтролируемо взаимодействуют между собой без посредников.
      </Text>
      
      <hr className="footer__line"/>
      
      <Text size={13} className="container--first-screen footer__text footer__text--copy">© 2021 Everin. All Rights
        Reserved</Text>
    </footer>
  );
};

export default Footer;
