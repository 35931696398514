import Company from "./Company.jsx";
import Curve from "./Curve.jsx";
import Twitter from "./Twitter";
import FaceBook from "./FaceBook";
import Telegram from "./Telegram";
import Youtube from "./Youtube";

const Icons = {
  Company,
  Curve,
  Twitter,
  FaceBook,
  Telegram,
  Youtube
};

export default Icons;
