import React from 'react';

const Telegram = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 54 54" version="1.1" >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-258.000000, -633.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Group-4" transform="translate(258.000000, 633.000000)">
            <path d="M27.4999492,47.8124885 C31.3384821,47.8124885 34.8905566,46.852855 38.1561738,44.9335891 C41.421791,43.0143233 44.0142332,40.4218811 45.933499,37.1562639 C47.8527648,33.8906467 48.8123984,30.3385723 48.8123984,26.5000393 C48.8123984,22.6615064 47.8527648,19.109432 45.933499,15.8438147 C44.0142332,12.5781975 41.421791,9.98575536 38.1561738,8.06648954 C34.8905566,6.14722372 31.3384821,5.18759015 27.4999492,5.18759015 C23.6614162,5.18759015 20.1093418,6.14722372 16.8437246,8.06648954 C13.5781074,9.98575536 10.9856652,12.5781975 9.06639939,15.8438147 C7.14713357,19.109432 6.1875,22.6615064 6.1875,26.5000393 C6.1875,30.3385723 7.14713357,33.8906467 9.06639939,37.1562639 C10.9856652,40.4218811 13.5781074,43.0143233 16.8437246,44.9335891 C20.1093418,46.852855 23.6614162,47.8124885 27.4999492,47.8124885 Z M34.4608701,36.296891 C34.2317035,37.4427217 33.6014971,37.7291797 32.5702496,37.1562639 L32.5702496,37.1562639 L27.2421373,33.2031484 L24.6640184,35.6953299 C24.3202693,36.0390791 23.9478748,36.2109537 23.5468336,36.2109537 L23.5468336,36.2109537 L23.9765201,30.7969041 L33.859309,21.8594254 C34.0311836,21.6875508 34.0311836,21.5729682 33.859309,21.5156762 C33.6874344,21.4583843 33.4582679,21.5156762 33.1718107,21.6875508 L33.1718107,21.6875508 L20.9687148,29.3359701 L15.7265398,27.7031615 C15.0963334,27.5312869 14.7955528,27.273475 14.8241982,26.9297258 C14.8528435,26.5859766 15.2395613,26.2995194 15.9843516,26.0703529 L15.9843516,26.0703529 L36.5233652,18.0781844 C36.981697,17.9063098 37.3684148,17.9779238 37.6835187,18.2930277 C37.9986225,18.6081315 38.0988818,19.109432 37.9842992,19.7969303 L37.9842992,19.7969303 L34.4608701,36.296891 Z" id=""/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Telegram;