import * as React from "react";
import {useState} from "react";
import "../page-styles/index.scss";
import variants from "../utils/variants";
import AnimatedInView from "../components/HOC/AnimatedInView";
import Layout from "../components/Layout";
import Title from "../components/typography/Title/Title";
import Text from "../components/typography/Text/Text";
import AboutCardList from "../components/AboutCardList/AboutCardList";
import MembersLine from "../images/about-cards/graphik.svg";
import PriceLine from "../images/about-cards/graphik_members.svg";
import Baloon from "../components/Baloon";
import Button from '../components/Button';
import BenefitsList from "../components/BenefitsList/BenefitsList";
import MobileDecentral from "../images/mobile-decentral.svg";
import MiningBlocks from "../images/mining-blocks.svg";
import CryptoNetwork from "../images/crypto-network-decentral.svg";
import Wallet from "../images/wallet.png";
import useWinSize from "../utils/useWinSize";
import PlanetTabs from "../components/PlanetTabs/PlanetTabs";
import PlanetTabsMobile from "../components/PlanetTabs/PlanetTabsMobile";
import FAQList from "../components/FAQList/FAQList";
import Slide1 from "../images/Everin Products Icons/EVER{in-API}.svg";
import Slide2 from "../images/Everin Products Icons/EVER{in-research & communication.svg";
import Slide3 from "../images/Everin Products Icons/EVER{in-exchange}.svg";
import Slide4 from "../images/Everin Products Icons/EVER {in-vote}.svg";
import Slide5 from "../images/Everin Products Icons/EVER{in-crowdfunding}.svg";
import Slide6 from "../images/Everin Products Icons/EVER{in-NFT Platform}.svg";
import Slide7 from "../images/Everin Products Icons/EVER {in-wallet} +Spatium.svg";
import Slide8 from "../images/Everin Products Icons/EVER{in-market}.svg";
import Slide9 from "../images/Everin Products Icons/EVER{in-foundation}.svg";
import Slide10 from "../images/Everin Products Icons/EVER {in-trust} + SVORT.svg";
import FeedBackForm from '../components/Forms/FeedBackForm'
import Modal, {handleModalClose} from '../components/Modal/Modal.jsx'

const IndexPage = () => {
  const {width: screenWidth} = useWinSize();
  const [modal, setModal] = useState(null);
  const okMsg = <Modal onClick={({target}) => handleModalClose(target, setModal)}><Title>Ваше сообщение успешно
    отправлено.</Title></Modal>
  const toggleModal = (e) => {
    e.preventDefault();
    setModal(<Modal onClick={({target}) => handleModalClose(target, setModal)}>
      <Title className="modal__title">Обратная связь</Title>
      <FeedBackForm handleClose={() => setModal(okMsg)}/>
    </Modal>)
  }
  const infoCards = [
    {
      name: "Дата создания",
      numbers: "27.01 2021",
      tooltip: "Дата запуска смарт-контрактов и листинга монеты на бирже JustSwap.",
    },
    {
      name: "Эмиссия токенов",
      numbers: "20 mln ",
      tooltip: "Количество выпущенных токенов на данный момент времени.",
    },
    {
      name: "Участники",
      img: MembersLine,
      numbers: "4628",
      tooltip: "Чем больше наше комьюнити, тем сильнее и стабильнее становиться экосистема.",
    },
    {
      name: "Прибыль сообщества",
      numbers: "$3 324 241",
      tooltip: "Прибыль всех участников системы за весь период ее работы",
    },
    {
      name: "Эмитировано токенов",
      numbers: "628",
      tooltip: "Добавочная эмиссия токенов при выполнении условий в смартконтракте.",
    },
    {
      name: "Цена токена",
      img: PriceLine,
      numbers: "$1982",
      tooltip: "Рыночная цена токена EVER на бирже.",
    },
    {
      name: "Ликвидность токена",
      numbers: "$500 00",
      tooltip: "Количество ликвидности токена выраженной в долларах США.",
    },
    {
      name: "Прибыль со стейкинга",
      numbers: "$800 00",
      tooltip: "Прибыль всех участников стейкинга за весь период работы смартконтракта.",
    },
  ];
  const benefits = [
    {
      text: "Высылая инвайты для новых участников сообщества",
      img: MobileDecentral,
      markerPosition: "right",
    },
    {
      text: "Получая дивиденды благодаря стейкингу токенов",
      img: MiningBlocks,
      markerPosition: "center",
    },
    {
      text: "Торгуя токенами на децентрализованной бирже",
      img: CryptoNetwork,
      markerPosition: "left",
    },
  ];
  const faq = [
    {
      title: "Что такое Everin?",
      text:
        "Create custom landing pages with Everin that converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding. with Omega that converts more visitors than any website.",
    },
    {
      title: "Что такое реферальная программа Everin?",
      text:
        "Create custom landing pages with Everin that converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding.",
    },
    {
      title: "Кто управляет Everin?",
      text:
        "Create custom landing pages with Everin that converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding.",
    },
    {
      title: "Что такое TRON?",
      text:
        "Create custom landing pages with Everin tthat converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding. with Everin t that converts more visitors than any website.",
    },
    {
      title: "Что такое смарт-контракт?",
      text:
        "Create custom landing pages with Everin t that converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding.",
    },
    {
      title: "Как покупать/продавать монету TRON?",
      text:
        "Create custom landing pages with Everin that converts more visitors than any website. With lots of unique blocks, you can easily build a page without any design or custom coding.",
    },
  ];
  const tabsData = [
    {
      id: 1,
      title: "EVER{in-API}",
      img: Slide1,
      text:
        "Открытые API к смартконтрактам Everin, которые обеспечат возможность интегрировать решение и подключиться к экосистеме Everin. ",
    },
    {
      id: 2,
      title: "EVER{in-research & communication}",
      img: Slide2,
      text:
        "Сервис по объединению специалистов, стартапов и энтузиастов из мира криптовалют и блокчейна. Сервис будет местом, где экосистема Everin сможет находить через пользователей новые идеи стартапов или инновационных решений, а  так же собирать команду специалистов, консультантов, сотрудников и инвесторов для  их реализации.",
    },
    {
      id: 3,
      title: "EVER{in-exchange}",
      img: Slide3,
      text:
        "В экосистему будет добавлен авангардный представитель децентрализации в мире криптовалют - P2P обменник, где пользователи сами создают ликвидность и покупают и продают криптовалюту за фиатные средства во многих актуальных и популярных платежных системах мира. Как всемирных так и локальных, например, WeChat Pay.",
    },
    {
      id: 4,
      title: "EVER {in-vote}",
      img: Slide4,
      text:
        "Главный принцип децентрализации, это возможность управлять и влиять на развитие проекта самим участникам. Поэтому все участники системы Everin смогут выбирать и предлагать проекты, идеи и векторы развития проекта на специальной платформе по принципу голосования. Платформа будет развиваться исходя из тех проектов и решений, которые будут набирать наибольшее количество голосов и потенциала раскрытия экосистемы.",
    },
    {
      id: 5,
      title: "EVER{in-crowdfunding}",
      img: Slide5,
      text:
        "Сервис в экосистеме, который  благодаря смартконтрактам и новым подходам выполнения условий даст возможность пользователям реализовывать свои цели и проекты благодаря поддержке сообщества. Помогая другим в достижении целей и мечт пользователи будут расширять свои возможности в получение средств для реализации своих целей.",
    },
    {
      id: 6,
      title: "EVER{in-NFT Platform}",
      img: Slide6,
      text:
        "Сервис по продаже цифровых произведений искусств с помощью токенизации. Любой желающий сможет продать или купить право владения интеллектуальной собственностью любому желающему. Торги будут происходить по принципу ставок или фиксированной стоимости. ",
    },
    {
      id: 7,
      title: "EVER {in-wallet} +Spatium",
      img: Slide7,
      text:
        "Криптокошелек с возможностью создать физическую платежную карту привязанную к кошельку для осуществления бесконтактных расчетов в любой точке мира. При этом средства всегда будут храниться в криптовалюте на кошельке пользователя и будут конвертироваться в момент оплаты.",
    },
    {
      id: 8,
      title: "EVER{in-market}",
      img: Slide8,
      text:
        "Торговая площадка товаров с возможностями приобретения их за криптовалюту. При этом товары смогут размещать мерчанты, которых сообщество само выбирает путем голосования.",
    },
    {
      id: 9,
      title: "EVER{in-foundation}",
      img: Slide9,
      text:
        "Независимый фонд экосистемы, где пользователи смогут инвестировать и получать доход, так и создавать свои пулы на сбор инвестиций в управление. Таким образом пользователь может инвестировать и получать прибыль или аккумулировать средства для торговли, делиться прибылью с инвесторами и зарабатывать сам.",
    },
    {
      id: 10,
      title: "EVER {in-trust} + SVORT",
      img: Slide10,
      text:
        "Система верификации аккаунта для обеспечения безопасности и антихрупкости проекта. Каждый пользователь создаст свой уникальный токен подтверждающий личность для достижения последнего 20-го уровня в системе, который открывает максимальные возможности в рамках экосистемы и ее продуктов.\n" +
        "Все данные будут обрабатываться исключительно внутри смартконтракта, что обеспечивает сохранность данных пользователя и никто не может получить к ним доступ, даже разработчики.",
    },
  ];
  
  return (
    <>
      <Layout title="EVERIN | Home Page" isHome={true}>
        <main className="home-content">
          <section id="about" className="about ">
            <AboutCardList items={infoCards}/>
            <AnimatedInView
              variants={screenWidth > 960 ? variants.fadeLeft : variants.fadeRight}
              threshold={0.1}
              duration={1}
              className="container about__text"
            >
              <Title className="about__title">О нас</Title>
              <Text size={15} noMedia>
                Everin — это DeFi сообщество с открытым доступом только для приглашенных пользователей. Проект создан по
                принципу полной финансовой прозрачности и призван реализовать финансовый потенциал всех пользователей
                мира путем их объединения.
              </Text>
              <Text size={15} noMedia>
                Создатели Everin разработали экосистему и реализовали все принципы её работы в трех смартконтрактах,
                которые контролируют и реализовывают все процессы системы. Антихрупкость и безопасность системы
                заключается в невозможности изменения исходного кода смартконтракта и возможности повлиять на кешфлоу
                разработчиками и создателями. В экосистеме Мы — пользователи растем и масштабируемся по мере прохождения
                20-уровневой матричной системы, где каждый шаг открывает новые финансовые возможности, а в будущем и
                доступ к уникальным продуктам.
              </Text>
              <Text size={15} noMedia>
                Получите свой личный инвайт в Everin. Это ваш ключ к раскрытию возможностей мировой экосистемы DeFi.
              </Text>
            </AnimatedInView>
          </section>
          
          <section id="passiveIncome" className="benefits container">
            <Baloon size="small" position={{top: "400px", left: "-15%"}}/>
            <Baloon size="small" position={{top: "0", right: 0}}/>
            
            <AnimatedInView
              threshold={0.4}
              duration={1}
              variants={variants.fade}
            >
              <Title className="benefits__title">Три способа для зароботка:</Title>
            </AnimatedInView>
            
            <BenefitsList items={benefits}/>
            
            <div className="benefits__img">
              <img src={Wallet} alt="wallet"/>
            </div>
            
            <Title id="ecoSystem" className="benefits__title benefits__title--special">
              Ever<span>[</span>in<span>]</span> экосистема
            </Title>
            <p className="benefits__text">
              На основе регулярных голосований среди всех членов сообществ будут приниматься решения по внедрению и
              разработке новых продуктов. Голос каждого имеет значение.
            </p>
          </section>
          
          {screenWidth >= 961 && <PlanetTabs data={tabsData}/>}
          {screenWidth < 960 && <PlanetTabsMobile data={tabsData}/>}
          
          <section className="slogan">
            <Baloon
              size={screenWidth > 960 ? "medium" : "small"}
              opacity="0.44"
              position={{bottom: "-10%", left: "0"}}
            />
            <Baloon
              size={screenWidth > 960 ? "extralarge" : "small"}
              opacity="0.44"
              position={{top: "-20%", right: "-10%"}}
            />
            <div className="container">
              <div id="token" className="wrapper">
                <div className="slogan__text-wrap">
                  <Title size="52px" className="slogan__title">
                    LP Token — <br/>
                    Твой Пассивный доход.
                  </Title>
                  <Text size={14} noMedia className="slogan__text">
                    Стань членом сообщества и зарабатывай наравне со всеми
                  </Text>
                </div>
                {screenWidth > 960 && <Button
                  text="Смотреть видео"
                  linkURL="https://www.youtube.com/channel/UCMDXYIG2RpfEoENUOnaUPeA/featured"
                  type="contained"
                  color="#0048d6"
                  className="slogan__btn"
                />}
              </div>
              <ul className="slogan__list">
                <li>10% от мирового оборота, то есть от каждой транзакции в сервисе Everin распределяются
                  смарт-контрактом среди держателей LP Token (EVER-TRX Justswap LP)
                </li>
                <li>0,3% от всех транзакций в пуле начисляются владельцам LP Token (EVER-TRX Justswap LP)</li>
                <li>100% выплат обеспечены смартконтрактом</li>
              </ul>
              {screenWidth <= 960 && <Button
                text="Смотреть видео"
                linkURL="https://www.youtube.com/channel/UCMDXYIG2RpfEoENUOnaUPeA/featured"
                type="contained"
                color="#0048d6"
                className="slogan__btn"
              />}
            </div>
          </section>
          
          <section id="faq" className="faq container">
            <Title className="faq__title">Frequently Asked Question</Title>
            <Text className="faq__text">
              Create custom landing pages with Omega that converts.
            </Text>
            <FAQList items={faq}/>
            <Text className="faq__link">
              Не удалось найти ответ на ваш вопрос? Свяжитесь с нами <a id="connect" href="#"
                                                                        onClick={toggleModal}>здесь</a>
            </Text>
          </section>
        </main>
      </Layout>
      {modal}
    </>
  );
};

export default IndexPage;
