import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import variants from "../../utils/variants";

const getActiveContent = (data, active) => data.find(({id}) => id === active);

const TabContent = ({data, active, duration = 300}) => {
  const [animationState, setAnimationState] = useState("hidden");
  let [activeComponent, setActiveContent] = useState({
    title: "",
    img: "",
    text: "",
  });
  
  useEffect(() => {
    setActiveContent(getActiveContent(data, active));
    setAnimationState("visible");
  }, []);
  
  useEffect(() => {
    setAnimationState("hidden");
    setTimeout(() => {
       setActiveContent(getActiveContent(data, active));
    }, duration);
    
    setTimeout(() => {
       setAnimationState("visible");
    }, duration * 2);
  }, [active]);
  
  return (
    <motion.div
      id="tabsContent"
      animate={animationState}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: "75px" },
      }}
      duration={duration}
      className={`planet-content__item`}
    >
      <div className="planet-content__title">
        <img src={activeComponent.img} alt={activeComponent.title}/>
        <h3>{activeComponent.title}</h3>
      </div>
      <p className="planet-content__text">{activeComponent.text}</p>
    </motion.div>
  );
};

export default TabContent;
