import React from "react";
import styled from "styled-components";
import Circle from '../images/Circle/Circle 1.png';

const BaloonStyled = styled.div`
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: normal;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({position, filter, opacity}) => ({
  ...position,
  opacity: opacity || 0.24,
  // backgroundImage: `-webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(161,73,179,1) 100%)`,
  // backgroundImage: `-moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(161,73,179,1) 100%)`,
  // backgroundImage: `-ms-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(161,73,179,1) 100%)`,
  // backgroundImage: `-o-linear-gradient(180deg, rgba(255,255,255,${opacity}) 0%, rgba(161,73,179,${opacity}) 100%)`,
  // backgroundImage: `linear-gradient(180deg, rgba(255,255,255,${opacity}) 0%, rgba(161,73,179,${opacity}) 100%)`,
  background: `url("${Circle}") center no-repeat`,
  filter: filter || "blur(73.3936px)",
})}
  
  ${({size}) => {
  switch (size) {
    case "large":
      return {
        width: "480px",
        height: "480px",
      };
    case "small":
      return {
        width: "270px",
        height: "270px",
      };
    case "medium":
      return {
        width: "340px",
        height: "340px",
      };
    case "extralarge":
      return {
        width: "626px",
        height: "626px",
      };
  }
}
}
  & img {
    width: 100%;
    height: 100%;
  }

`;

// export default (props) => <BaloonStyled {...props}><img src={Circle} alt="circle"/></BaloonStyled>;
export default (props) => <BaloonStyled {...props}>
  <img src={Circle} alt="circle" loading="lazy"/>
</BaloonStyled>;
