const fade = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const fadeUp = {
  visible: { opacity: 1, transform: "translateY(0)" },
  hidden: { opacity: 0, transform: "translateY(300px)" },
};

const showUp = (delta) => ({
  visible: { opacity: 1, transform: "translateY(0)" },
  hidden: { opacity: 0, transform: `translateY(${delta}px)` },
});

const fadeDown = {
  hidden: { opacity: 0, transform: "translateY(-300px)" },
  visible: { opacity: 1, transform: "translateY(0)" },
};

const fadeLeft = {
  visible: { opacity: 1, transform: "translateX(0)" },
  hidden: { opacity: 0, transform: "translateX(-300px)" },
};

const fadeRight = {
  visible: { opacity: 1, transform: "translateX(0)" },
  hidden: { opacity: 0, transform: "translateX(300px)" },
};

const variants = {
  fade,
  fadeUp,
  fadeDown,
  fadeLeft,
  fadeRight,
  showUp,
};

export default variants;
