import React, {useEffect, useState} from "react";
import styled from "styled-components";
import allVariants from "../../utils/variants";
import {useInView} from "react-intersection-observer";

const isBrowser = typeof window !== "undefined";

function getCoords(elem) {
  let box = elem.getBoundingClientRect();
  
  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset
  };
}

const AnimatedInView = ({
                          component = "div",
                          children,
                          variants = allVariants.fade,
                          threshold = 0.5,
                          duration = 0.8,
                          className,
                          ...rest
                        }) => {
  const [isPlaying, setIsPlaying] = useState("paused");
  const [skip, setSkip] = useState(false);
  const [ref, inView] = useInView({triggerOnce: true, threshold, initialInView: false, skip});
  const [classes, setClasses] = useState(className);
  
  
  useEffect(() => {
    if (inView) {
      setSkip(false);
      setClasses(`${className} animating`)
      setIsPlaying("running");
    }
  }, [inView]);
  
  return (
    <Wrapper
      ref={ref}
      duration={duration}
      variants={variants}
      isPlaying={isPlaying}
      // animationName={name}
      className={classes}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default AnimatedInView;

const Wrapper = styled.div`
  transition: ${({duration}) => duration}s ease;
  will-change: transform, opacity;
    ${({variants}) => ({...variants.hidden})};
  // animation: forwards ${({animationName}) => animationName}
  //   ${({duration}) => duration}s ${({isPlaying}) => isPlaying} ease;
  // will-change: transform, opacity;
  
  &.animating {
    ${({variants}) => ({...variants.visible})};
  }

  // ${({variants}) => ({...variants.hidden})};
  //
  // @keyframes ${({animationName}) => animationName} {
  //   0% {
  //     ${({variants}) => ({...variants.hidden})}
  //   }
  //   100% {
  //   }
  // }
`;
