import React from "react";

const FaceBook = (props) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Artboard</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-497.000000, -633.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Group-2" transform="translate(497.000000, 633.000000)">
            <path
              d="M28.3769008,47.937493 L28.3769008,31.9648748 L23.0526948,31.9648748 L23.0526948,25.8144989 L28.3769008,25.8144989 L28.3769008,21.2246661 C28.3769008,18.5931615 29.1112741,16.573635 30.5800206,15.1660868 C32.0487671,13.7585385 33.9764968,13.0547637 36.3632099,13.0547637 C38.2603413,13.0547637 39.8514828,13.1159619 41.136636,13.238357 L41.136636,18.837953 L37.8319564,18.837953 C36.6691983,18.837953 35.8430284,19.113343 35.3534467,19.6641229 C34.9862601,20.0925078 34.8026667,20.826881 34.8026667,21.8672427 L34.8026667,25.8144989 L40.9530427,25.8144989 L40.1268728,31.9648748 L34.8026667,31.9648748 L34.8026667,47.937493 L45.2674855,47.937493 C45.9406606,47.937493 46.4914405,47.7080014 46.9198254,47.2490181 C47.3482102,46.7900348 47.562402,46.2545548 47.562402,45.6425766 L47.562402,9.10750745 C47.562402,8.43433244 47.3329103,7.8835525 46.873927,7.45516764 C46.4149437,7.02678278 45.8794637,6.81259105 45.2674855,6.81259105 L8.7324164,6.81259105 C8.05924139,6.81259105 7.50846146,7.02678278 7.08007659,7.45516764 C6.65169173,7.8835525 6.4375,8.43433244 6.4375,9.10750745 L6.4375,45.6425766 C6.4375,46.2545548 6.65169173,46.7900348 7.08007659,47.2490181 C7.50846146,47.7080014 8.05924139,47.937493 8.7324164,47.937493 L28.3769008,47.937493 Z"
              id=""/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FaceBook;
