import React, {useState} from "react";
import {motion} from "framer-motion";
import TabContent from "./TabContent";
import Line from "../Line.jsx";
import Icons from "../../utils/Icons/index";
import "./PlanetTabs.scss";
import Baloon from "../Baloon";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {generate as counter} from "shortid";

const MenuItem = ({children, onClick, isActive}) => (
  <div
    className={`planet-menu__item ${
      isActive ? "planet-menu__item--active" : ""
    }`}
  >
    <motion.p whileTap={{scale: 0.9}} onClick={onClick}>
      {children}
    </motion.p>
  </div>
);

const PlanetTabs = ({data}) => {
  const dataCopy = [...data];
  const [active, setActive] = useState(dataCopy[0].id);
  const half = Math.ceil(dataCopy.length / 2);
  
  const handleClick = (id) => {
    setActive(id);
  };
  
  const firstHalf = dataCopy.splice(0, half);
  const secondHalf = dataCopy.splice(-half);
  
  const mapToMenuItem = ({id, title}, k, arr) => (
    <MenuItem key={counter()} onClick={() => handleClick(id)} isActive={active === id}>
      <AnchorLink to="/#tabsContent" title={title}>{title}</AnchorLink>
    </MenuItem>
  );
  
  return (
    <section className="planet-tabs ">
      <div className="container">
        <Baloon
          size="large"
          opacity={0.24}
          position={{top: "-400px", right: "-15%"}}
        />
        <div className="planet-menu">
          <div className="planet-menu__col">{firstHalf.map(mapToMenuItem)}</div>
          <div
            className="planet-menu__col planet-bg-wrapper"
          >
            <Line
              size={{width: "1px"}}
              position={{
                top: "-10%",
                left: "50%",
                bottom: "85%",
                transform: "translateX(-50%)",
              }}
            />
            <Line
              size={{height: "1px"}}
              position={{
                left: 0,
                right: "80%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
            <Line
              size={{height: "1px"}}
              position={{
                right: 0,
                left: "80%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
            <Line
              size={{width: "1px"}}
              position={{
                top: "85%",
                bottom: "-10%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
            {/*<motion.div*/}
            {/*  animate={{ rotate: 360 }}*/}
            {/*  transition={{ duration: 20 }}*/}
            {/*  className="planet-gb company"/>*/}
            <motion.div className="company planet-bg"/>
            <motion.div
              whileHover={{height: 40}}
              whileTap={{height: 30}}
              className="planet-company">
              <Icons.Company />
            </motion.div>
          </div>
          <div className="planet-menu__col">{secondHalf.map(mapToMenuItem)}</div>
        </div>
        <TabContent data={data} active={active} duration={300}/>
      </div>
      <Baloon
        size="medium"
        opacity={0.24}
        position={{bottom: "-10%", left: "-10%"}}
      />
    </section>
  );
};

export default PlanetTabs;
