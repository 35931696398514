import React from 'react';

const Youtube = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 54 54" version="1.1">
      <title>Artboard Copy 3</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-391.000000, -633.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Group-3" transform="translate(391.000000, 633.000000)">
            <path
              d="M26.999941,44 L34.8202348,43.9140627 C40.3202217,43.7421881 43.8150055,43.4557309 45.3045848,43.0546898 C46.3358324,42.7682325 47.2238507,42.2526088 47.968641,41.5078184 C48.7134313,40.7630281 49.2290551,39.9036552 49.5155123,38.9296996 C49.9165535,37.3828283 50.2030107,35.0052293 50.3748853,31.7969041 L50.3748853,31.7969041 L50.4608226,27.5000393 L50.3748853,23.2031746 C50.2030107,20.05214 49.9165535,17.7031877 49.5155123,16.1563164 C49.2290551,15.1250688 48.7134313,14.2370506 47.968641,13.4922602 C47.2238507,12.7474699 46.3358324,12.2318461 45.3045848,11.9453889 C43.8150055,11.5443478 40.3202217,11.2578906 34.8202348,11.086016 L34.8202348,11.086016 L26.999941,11.0000787 L19.1796471,11.086016 C13.6796602,11.2578906 10.1848765,11.5443478 8.69529713,11.9453889 C7.66404959,12.2318461 6.77603131,12.7474699 6.03124098,13.4922602 C5.28645066,14.2370506 4.77082689,15.1250688 4.48436967,16.1563164 C4.08332853,17.7031877 3.79687131,20.05214 3.62499672,23.2031746 C3.56770475,24.8073379 3.53905943,26.2396252 3.53905943,27.5000393 L3.53905943,27.5000393 L3.62499672,31.7969041 C3.79687131,35.0052293 4.08332853,37.3828283 4.48436967,38.9296996 C4.77082689,39.9036552 5.28645066,40.7630281 6.03124098,41.5078184 C6.77603131,42.2526088 7.66404959,42.7682325 8.69529713,43.0546898 C10.1848765,43.4557309 13.6796602,43.7421881 19.1796471,43.9140627 L19.1796471,43.9140627 L26.999941,44 Z M22.1874525,34.5468975 L22.1874525,20.5391184 L34.4764857,27.5000393 L22.1874525,34.5468975 Z"
              id=""/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Youtube;