import React from "react";
import Icons from "../../utils/Icons";
import Line from "../Line";
import styled from "styled-components";
import useWinSize from "../../utils/useWinSize";
// import variants from "../../utils/variants";
// import AnimatedInView from "../HOC/AnimatedInView";
import {generate as counter} from "shortid";


const mapPositionToFlex = (pos) => {
  switch (pos) {
    case "center":
      return pos;
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return null;
  }
};

const mapPosition = (pos) => {
  switch (pos) {
    case "center":
      return {
        bottom: "-10px",
        left: "50%",
        transform: "translateX(-50%)",
      };
    case "left":
      return {
        left: "-15px",
        bottom: "50%",
        transform: "translateY(-50%)",
      };
    case "right":
      return {
        right: "-15px",
        bottom: "50%",
        transform: "translateY(-50%)",
      };
    default:
      return null;
  }
};

export const BenefitsItem = ({ text, img, markerPosition, length, k }) => {
  const {width: screenWidth} = useWinSize();
  return (
    <BenefitsItemStyled markerPosition={markerPosition} length={length}>
      <div className="item">
        <img src={img} alt="benefit icon" className="img" />
        <p>{text}</p>
        {screenWidth > 960 && <>
          {k === 0 && <Icons.Curve className={"itemFirst"} />}
          {k === length - 1 && <Icons.Curve className={"itemLast"} />}
          {k > 0 && k !== length - 1 && (
            <Line
              size={{ width: "1px", height: "200%" }}
              position={{
                top: "calc(100% + 10px)",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          )}
        </>}
        
      </div>
    </BenefitsItemStyled>
  );
};

const BenefitsItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${({ markerPosition, length }) => ({
    "flex-basis": `calc((100% / ${length}) - ((${length} - 1) * 10px))`,
    alignItems: mapPositionToFlex(markerPosition),
  })}
  
  &:first-child {
    margin-top: 150px;
    @media (max-width: 960px) {
     margin-top: 0;
    }
  }
  &:last-child {
    margin-top: 150px;
    @media (max-width: 960px) {
     margin-top: 0;
    }
  }

  & .item {
    font-family: "Graphik", sans-serif;
    font-size: 18px;
    color: #fff;
    ${({ markerPosition }) => ({
      textAlign: markerPosition,
    })};
    width: 217px;
    line-height: 26px;
    padding: 15px;
    box-sizing: initial;
    position: relative;
    
    @media (max-width: 960px) {
      width: 280px;
      text-align: center;
      font-size: 15px;
      box-sizing: border-box;
      transform: translateY(-50px);
    }

    &::after {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      border: 1px solid #5a5873;
      ${({ markerPosition = "center" }) => ({
        ...mapPosition(markerPosition),
      })};
      @media (max-width: 960px) {
       content: none;
      }
    }
  }

  & .itemFirst {
    position: absolute;
    width: 60%;
    height: auto;
    top: calc(50% - 10px);
    right: calc(-60% - 13px);
  }

  & .itemLast {
    position: absolute;
    width: 60%;
    height: auto;
    top: calc(50% - 10px);
    left: calc(-60% - 13px);
    transform: scale(-1, 1);
  }

  & .img {
    width: 44px;
    margin-bottom: 8px;
  }
`;

const BenefitsList = ({ items }) => {
  const itemsComponents = items.map((b, k) => (
    <BenefitsItem {...b} key={counter()} length={items.length} k={k} />
  ));
  return (
    <BenefitsListStyled className="container">
      {itemsComponents}
    </BenefitsListStyled>
  );
};

const BenefitsListStyled = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr fit-content(40%) 1fr;
  @media (max-width: 960px) {
    grid-template-columns: none ;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      90deg,
      transparent calc(50% - 1px),
      #5a5873 calc(50%),
      transparent calc(50% + 1px)
    );
  }
`;
export default BenefitsList;
