import React from "react";
import styled from "styled-components";

const Button = ({
  text,
  linkURL,
  type,
  color,
  textColor,
  className,
  ...rest
}) => {
  if (linkURL)
    return (
      <a href={linkURL} target="_blank" className={`.common ${type} ${className}`} {...rest}>
        {text}
      </a>
    );

  return (
    <button className={className} {...rest}>
      {text}
    </button>
  );
};

const Btn = styled(Button)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 23px;
  font-family: "Graphik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;

  ${({ type, color }) =>
    type === "outlined"
      ? {
          marginLeft: "100px",
          border: "1px solid #fff",
          paddingLeft: "50px",
          paddingRight: "50px",
          background: color || "none",
          color: "#fff",
        }
      : ""}
  ${({ type, color, textColor }) =>
    type === "contained"
      ? {
          paddingLeft: "25px",
          paddingRight: "25px",
          background: color || "none",
          color: textColor || "#fff",
        }
      : ""};
  
  @media (max-width: 1100px) {
    ${({type, size}) => type === 'outlined' ? {marginLeft: "30px", fontSize: '14px', padding: "15px 30px"} : {fontSize: `${(parseInt(size) || 18) * 0.8}px`} };
  }
  @media (max-width: 768px) {
    font-size: ${({size}) => `${(parseInt(size) || 18) * 0.7}px`};
  }
  @media (max-width: 375px){
    font-size: ${({size}) => `${(parseInt(size) || 18) * 0.61}px`};
  }
`;

export default Btn;
