import React from "react";
import styled from "styled-components";

const Title = ({
                 size = "42px",
                 weight = 400,
                 children,
                 className,
                 ...rest
               }) => {
  return (
    <h3 className={className} {...rest}>
      {children}
    </h3>
  );
};

const TextTitle = styled(Title)`
  font-family: "Graphik", sans-serif;
  font-size: ${({size}) => size || "42px"};
  font-weight: ${({weight}) => weight || 400};
  color: #fff;
  
  @media (max-width: 1100px) {
    font-size: ${({size}) => `${(parseInt(size) || 42) * 0.8}px`};
  }
  @media (max-width: 768px) {
    font-size: ${({size}) => `${(parseInt(size) || 42) * 0.7}px`};
  }
  @media (max-width: 480px){
    font-size: ${({size}) => `${(parseInt(size) || 42) * 0.6}px`};
  }
  @media (max-width: 375px){
    font-size: ${({size}) => `${(parseInt(size) || 42) * 0.5}px`};
  }
`;
export default TextTitle;
