import React from "react";

const Curve = (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 151 362"
      fill="none"
      {...props}
    >
      <path
        d="M150.121 361.5C150.121 361.5 153.561 194.731 125.819 127.518C98.0776 60.3059 1.12053 0.5 1.12053 0.5"
        stroke="#2f2d4c"
      />
    </svg>
  );
};

export default Curve;
